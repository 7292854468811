<template>
  <div>
    <h4>Preguntas Frecuentes
      <img src="@/assets/images/wh.png">
    </h4>
    <div class="faq_list">
      <div class="container">
        <!-- <h3 class="title">Frequently Asked Questions</h3> -->
        <!-- <img src="@/assets/images/loanImg/icon_faq.png" alt=""> -->
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item v-for="(item, index) in faqList" :id="item.id" :key="index" :title="item.title" :name="item.name">
            <div v-html="item.dec" />
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'],
      faqList: [
        {
          name: '1',
          id: 'question-1',
          title: '1. ¿Cómo obtener un préstamo en CaudalSube?',
          dec: 'Es muy fácil, confiamos en ti. Solo entra a Google appstore y descarga la app (app link), regístrate con tu número celular, sigue las instrucciones y listo! '
        },
        // {
        //   name: '2',
        //   id: 'question-2',
        //   title: '2. ¿CUAL ES LA FORMA DE REEMBOLSAR EL PRESTAMO?',
        //   dec: '1. Pago con Baloto<br>2. Corresponsales de STP<br>3. Doteaplus - payValida<br>4. PUNTO ROJO - Bancupo<br>5. Pague en línea a través de PSE, seleccione Pagar y realice pagos virtuales.'
        // },
        {
          name: '2',
          id: 'question-2',
          title: '2. Condiciones para solicitar un préstamo',
          dec: 'Únicamente necesitas: <br> - Ser Mexicano- Tener 18 años o más <br> - INE - Cuenta CLABE de un banco mexicano'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3. ¿Cómo utilizar mi préstamo?',
          dec: 'Tu dinero será enviado directamente a la cuenta bancaria que nos indiques para que lo utilices en cualquier momento y en lo que más te guste'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4. ¿por qué no se puede recibir el Código de verificación del mensaje de texto?',
          dec: 'Sí tu código parece demorar, puedes seleccionar la opción de verificación de llamada de voz. Intenta verificar sí tu teléfono funciona bien y tiene una señal estable. En muy raras ocasiones, el sistema podría estar saturado por lo que te recomendamos intentar de nuevo en unos minutos.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5. ¿ por qué se realiza el reconocimiento facial?',
          dec: 'Para nosotros lo primero es tu seguridad, este proceso te protege para que tu cuenta siempre sea tuya! En caso de necesitar ayuda, te recomendamos un ambiente con buena iluminación y verificar que los permisos del uso de la cámara estén activados, puedes encontrarlos en la configuración de tu celular.'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6. Quiero incrementar mi préstamo ¿Cómo puedo hacerlo?',
          dec: 'Sigue utilizando y pagando a tiempo tus préstamos, esto nos ayudará a conocerte mejor en el futuro y aumentar el montos de tus créditos.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7. ¿Puedo realizar mi pago en partes?',
          dec: 'Siempre que lo desees puedes realizar pagos todos los pagos que necesites para cubrir el monto especificado mientras que sea antes de la fecha de vencimiento.'
        },
        {
          name: '8',
          id: 'question-8',
          title: '8. ¿Puedo pagar mi préstamo por adelantado? ',
          dec: '¡Claro que sí! En la página de inicio de CaudalSube, encontrarás el monto a cubrir y si lo deseas puedes pagarlo en su totalidad. Lo tomaremos muy en cuenta y será considerado para un posible aumento en el monto de tus siguientes préstamos.'
        },
        {
          name: '9',
          id: 'question-9',
          title: '9. ¿Qué hago si no se ve reflejado mi pago?',
          dec: 'Recuerda que tu pago puede tardar hasta 48 horas en verse reflejado, ¡pero no te preocupes! Será registrado con la fecha en que lo hiciste y no se generarán cargos adicionales. Te notificaremos por mensaje de texto cuando recibamos el pago. Si después de 48 horas aún no ves tu pago reflejado, por favor envíanos tu comprobante al correo caudalsube@outlook.com y responderemos tu solicitud a más tardar el siguiente día hábil. Te sugerimos pagar antes de las 5PM, ya que esto podría ayudar a que se refleje con antelación.'
        },
        {
          name: '10',
          id: 'question-10',
          title: '10. ¿Qué pasa si pago más que el monto requerido?',
          dec: 'En caso de haber pagado un monto mayor al solicitado y que este cubra más de la totalidad de tu préstamo, por favor envía el comprobante de pago y tu número de teléfono registrado en la aplicación a caudalsube@outlook.com y responderemos tu solicitud a más tardar el siguiente día hábil. <br> Si aún te quedan pagos por realizar para terminar de pagar tu préstamo, el monto restante se abonará automáticamente a tu siguiente pago.'
        }
        // {
        //   name: '11',
        //   id: 'question-11',
        //   title: '11. ¿Cómo puedo aumentar mi crédito?',
        //   dec: 'La línea de crédito es generada automáticamente por el sistema, manteniendo un excelente y oportuno reembolso, lo que le permite obtener más dinero la próxima vez que pida prestado.'
        // }
      ]
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    }
  }

}
</script>

<style lang="scss" scoped>
h4{
  width: 100%;
  padding:00px calc((100% - 1100px) / 2) 0;
  // background: linear-gradient(270deg, #C54347 0%, #7149BC 100%);
  background: #063EA4;
  color: white;
  line-height: 120px;
  font-size: 25px;
  img{
    float: right;
    width: 100px;
    margin: 10px 120px 0 0;
  }
}
.faq_list{
  min-width: 1200px;
  padding: 50px calc((100% - 1100px) / 2) 50px;
  margin: 0 auto;
  img{
    display: block;
    margin: 0 auto;
    width: 350px;
    height: 300px;
  }
  .title{
    font-size: 30px;
    color: #333333;
    margin-bottom: 40px;
  }
}
:deep(.el-collapse-item__header) {
  background-color:#EEEEEE;
  padding-left: 20px;
}
:deep(.el-collapse-item__content) {
  padding: 20px 480px 50px 20px;
}
@media only screen and (max-width: 665px){
  h4{
    padding-left: 20px;
    font-size: 12px;
    line-height: 50px;
    img{
      width: 40px;
      margin: 5px 30px 0 0;
    }
  }
  .faq_list{
    min-width: calc(100vw);
    padding: 0 !important;
    .container{
      img{
        width: 250px;
        height: 200px;
        margin: 10px auto;
      }
    }
  }
  :deep(.el-collapse-item__header) {
    padding-left: 20px;
    font-size: 12px !important;
    line-height: 24px !important;
    height: 50px !important;
  }
  :deep(.el-collapse-item__content) {
    padding: 20px 20px !important;
    font-size: 12px !important;
  }
}
</style>
